import { useCallback, useEffect, useRef } from "react";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";

/** Default delta time in milliseconds used to poll the callback */
export const DEFAULT_POLLING_INTERVAL = 60000;

/** Default maximum number of failed attempts before the polling is stopped */
export const DEFAULT_MAX_ATTEMPTS = 10;

interface Props {
  /** Callback to poll */
  callback(): Promise<void>;

  /** Optional callback to execute on a failed first attempt (= first callback() has failed) */
  errorHandlerFirstLoad?(error: unknown): void;

  /** Optional callback to execute on the first error. Skipped if errorHandlerFirstLoad() was called for same error. */
  errorHandler?(error: unknown): void;

  /** Message to display when the max numbered of failed attempts has been reached */
  maxAttemptsMsg: string;

  /** Delta time in milliseconds used to poll the callback. Default is 60000 */
  pollingDelta?: number;

  /** Maximum number of failed attempts before the polling is stopped. Default is 10 */
  maxAttempts?: number;
}

/**
 * Executes the given callback at an specific interval defined by the given polling delta.
 * It stops polling when the maximum number of failed attempts is reached.
 */
export function usePolling({
  callback,
  errorHandlerFirstLoad,
  errorHandler,
  pollingDelta = DEFAULT_POLLING_INTERVAL,
  maxAttempts = DEFAULT_MAX_ATTEMPTS,
  maxAttemptsMsg,
}: Props): void {
  const { handleErrorWithToast } = useErrorContext();

  /** True until the first error was seen. */
  const isFirstFailedAttempt = useRef<boolean>(true);
  /** Counts the total calls of callback(). */
  const totalCounterRef = useRef<number>(0);
  /** Counts the number of consecutive failed attempts. */
  const failedCounterRef = useRef<number>(0);
  /** The setInterval handle, or undefined if we're currently not polling. */
  const pollingIntervalIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

  /** Stops polling */
  const stopPolling = useCallback(() => {
    clearInterval(pollingIntervalIdRef.current);
    pollingIntervalIdRef.current = undefined;
  }, []);

  /**
   * Executes the given callback.
   *
   * If it succeeds the failed counter is reset.
   *
   * If it throws an exception it increases the failed attempts count and:
   * - Executes the passed error handler if is the 1st failed attempt
   * - Stops polling if failed attempts reach the max attempts
   */
  const executeCallback = useCallback(async () => {
    totalCounterRef.current += 1;
    try {
      await callback();
      failedCounterRef.current = 0;
    } catch (error) {
      // Increment the number of consecutive failed attempts.
      failedCounterRef.current += 1;
      // Call handler for first-load error, or for first error.
      if (totalCounterRef.current === 1 && errorHandlerFirstLoad) {
        isFirstFailedAttempt.current = false;
        errorHandlerFirstLoad(error);
      } else if (isFirstFailedAttempt.current && errorHandler) {
        isFirstFailedAttempt.current = false;
        errorHandler(error);
      }

      if (failedCounterRef.current >= maxAttempts) {
        stopPolling();
        handleErrorWithToast({
          id: `usePolling-${Date.now().toString()}`,
          title: maxAttemptsMsg,
          error,
        });
      }
    }
  }, [
    callback,
    errorHandlerFirstLoad,
    errorHandler,
    handleErrorWithToast,
    maxAttempts,
    maxAttemptsMsg,
    stopPolling,
  ]);

  /**
   * Executes the callback the very 1st time on component mount
   * then it polls the callback by the given delta time.
   * It also stops the polling on component unmount.
   */
  useEffect(() => {
    if (!pollingIntervalIdRef.current) {
      executeCallback();
    }

    pollingIntervalIdRef.current = setInterval(executeCallback, pollingDelta);

    return () => stopPolling();
  }, [executeCallback, pollingDelta, stopPolling]);
}
