import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { ProjectTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { ProjectOverview } from "@pages/project-details/project-overview/project-overview";
import { ProjectOverviewActionButtons } from "@pages/project-details/project-overview/project-overview-action-buttons";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  fetchingProjectsFlagsSelector,
  selectedProjectContextSelector,
  selectedProjectSelector,
  shouldDisplayMarkupsForViewersSelector,
} from "@store/projects/projects-selector";
import { useEffect, useMemo } from "react";
import {
  fetchProjectContext,
  fetchProjectDetails,
  fetchProjectSettings,
} from "@store/projects/projects-slice-thunk";
import { useCoreApiClient } from "src/api/use-core-api-client";
import { ProjectTeam } from "@pages/project-details/project-team/project-team";
import { ProjectBackgroundTasks } from "@pages/project-details/project-bg-tasks/project-background-tasks";
import { hasSubscriptionProjectLevel } from "@utils/access-control/project/project-access-control";
import { RequiredRoleProjectLevelName } from "@utils/access-control/project/project-access-control-types";
import { ProjectSettings } from "@pages/project-details/project-settings/project-settings";
import { ProjectSnapshots } from "@pages/project-details/project-snapshots/project-snapshots";
import ComingSoonIcon from "@assets/icons/new/coming-soon_60px.svg?react";
import WarningIcon from "@assets/icons/new/warning_60px.svg?react";
import { APITypes } from "@stellar/api-logic";
import { OpenProjectButton } from "@pages/project-details/open-project-button";
import { ProjectBackgroundTasksSkeleton } from "@pages/project-details/project-bg-tasks/project-background-tasks-skeleton";
import { DataManagementButton } from "@pages/project-details/project-data-management/data-management-button";
import { ProjectMarkups } from "@pages/project-details/project-markups/project-markups";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { contactSupport } from "@utils/email-utils";
import { ContactSupportLocations } from "@custom-types/types";
import { FaroButton } from "@components/common/faro-button";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { ProjectData } from "@pages/project-details/project-data/project-data";
import {
  isAlphaTestingEnabledSelector,
  isBetaTestingEnabledSelector,
} from "@store/app/app-selector";
import { getProjectApiClient } from "@api/project-api/project-api-utils";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { fetchTeams } from "@store/teams/teams-slice-thunk";
import { ProjectIntegrations } from "@pages/project-details/project-integrations/project-integrations";

/**
 * Display the details of the selected project
 */
export function ProjectDetails(): JSX.Element | null {
  const { companyId, projectId, projectTabs } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(selectedProjectSelector);
  const {
    isFetchingProjects,
    isFetchingProjectContext,
    isFetchingProjectSettings,
  } = useAppSelector(fetchingProjectsFlagsSelector);
  const selectedProjectContext = useAppSelector(selectedProjectContextSelector);
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);
  const shouldDisplayMarkupsForViewers = useAppSelector(
    shouldDisplayMarkupsForViewersSelector
  );

  /** Determines if the selected project is allowed to see project snapshots */
  const isProjectAllowedToViewSnapshotsTab = useMemo(() => {
    const hasPermissionGlobalSnapshotView = hasSubscriptionProjectLevel({
      projectContext: selectedProjectContext,
      requiredProjectSubscriptionRole: [
        APITypes.EUserSubscriptionRole.globalSnapshotView,
      ],
    });
    const hasPermissionGlobalSnapshotCreate = hasSubscriptionProjectLevel({
      projectContext: selectedProjectContext,
      requiredProjectSubscriptionRole: [
        APITypes.EUserSubscriptionRole.globalSnapshotCreate,
      ],
    });

    const shouldHideTabSnapshot =
      selectedProject?.archivingState === APITypes.ArchivingState.ARCHIVED;

    return (
      !shouldHideTabSnapshot &&
      (hasPermissionGlobalSnapshotView || hasPermissionGlobalSnapshotCreate)
    );
  }, [selectedProject?.archivingState, selectedProjectContext]);

  /** Whether the required data to display the project details page is loading */
  const isLoading =
    isFetchingProjects || isFetchingProjectContext || isFetchingProjectSettings;

  /**
   * Fetches the required data to display the project details:
   * - project details
   * - project context
   * - project settings
   */
  useEffect(() => {
    if (projectId) {
      const projectApiClient = getProjectApiClient({
        projectId,
      });

      dispatch(fetchProjectSettings({ projectApiClient }));
      dispatch(fetchProjectContext({ coreApiClient, projectId }));

      if (companyId) {
        dispatch(fetchProjectDetails({ coreApiClient, companyId, projectId }));
        isAlphaTestingEnabled &&
          dispatch(fetchTeams({ coreApiClient, companyId }));
      }
    }
  }, [companyId, coreApiClient, dispatch, isAlphaTestingEnabled, projectId]);

  /**
   * Early exit to the "Not found" page if the requires URL route params are not defined or their value is not valid:
   * - companyId
   * - projectId
   * - projectTabs
   */
  if (
    !companyId ||
    !projectId ||
    !projectTabs ||
    !Object.values(ProjectTabs).includes(projectTabs)
  ) {
    return <NotFoundPage />;
  }

  if (projectTabs === ProjectTabs.integrations && !isAlphaTestingEnabled) {
    return (
      <EmptyPage
        title="Coming soon"
        subtitle="This page is still under development and will be available soon"
        icon={ComingSoonIcon}
      />
    );
  }

  if (projectTabs === ProjectTabs.evaluation) {
    return (
      <EmptyPage
        title="The feature is no longer supported"
        subtitle="The feature you are looking for is no longer supported in Sphere XG"
        icon={WarningIcon}
        button={
          <FaroButton
            onClick={() =>
              contactSupport({
                location:
                  ContactSupportLocations.projectEvaluationDeprecatedPage,
                trackEvent,
              })
            }
          >
            Contact support
          </FaroButton>
        }
      />
    );
  }

  /**
   * Early exit to the "Not found" page if after fetching the required data to show the project details
   * one data element is not defined:
   * - project details
   * - project context
   * - project settings
   */
  if (
    (selectedProject === null || selectedProjectContext === null) &&
    !isLoading
  ) {
    return <NotFoundPage />;
  }

  /** Shows a button to open the project in Sphere Viewer */
  const projectButtons = selectedProject ? (
    <>
      {!isLoading && selectedProject && (
        <ProjectOverviewActionButtons project={selectedProject} />
      )}
      <DataManagementButton project={selectedProject} isLoading={isLoading} />
      <OpenProjectButton project={selectedProject} isLoading={isLoading} />
    </>
  ) : undefined;

  return (
    <TabsWithRoutes
      selectedTab={projectTabs}
      selectedProject={selectedProject}
      requiredAttribute={selectedProject}
      trackingEventPageName={OpenTabEvents.openProjectTab}
      isLoading={isLoading}
      tabs={[
        {
          label: "Overview",
          route: ProjectTabs.overview,
          content: (project) => (
            <ProjectOverview project={project} isLoading={isLoading} />
          ),
          loadingContent: <ProjectOverview isLoading={true} />,
          actionButtons: projectButtons,
        },
        {
          label: "Members",
          route: ProjectTabs.members,
          content: (project) => (
            <ProjectTeam
              project={project}
              companyId={companyId}
              isLoading={isLoading}
            />
          ),
          loadingContent: <ProjectTeam isLoading={true} />,
          actionButtons: projectButtons,
          requiredRoleProjectLevel:
            RequiredRoleProjectLevelName.canViewAllProjectMembers,
          isHidden:
            // Even if the user has permissions, hide the teams tab if the project details
            // object does not have the members property because the project details were requested
            // by user who is not a project admin or there was an error in the request.
            !selectedProject || selectedProject.members.length === 0,
        },
        {
          label: "Data",
          route: ProjectTabs.data,
          content: (project) => <ProjectData projectId={project.id} />,
          loadingContent: null,
          actionButtons: projectButtons,
          requiredRoleProjectLevel:
            RequiredRoleProjectLevelName.canViewProjectData,
          isHidden: !isBetaTestingEnabled,
        },
        {
          label: "Activity",
          route: ProjectTabs.activity,
          content: (project) => (
            <ProjectBackgroundTasks projectId={project.id} />
          ),
          loadingContent: <ProjectBackgroundTasksSkeleton />,
          actionButtons: projectButtons,
          requiredRoleProjectLevel:
            RequiredRoleProjectLevelName.canViewProjectCloudActivity,
        },
        {
          label: "SnapShots",
          route: ProjectTabs.snapshots,
          content: (project) => (
            <ProjectSnapshots projectId={project.id} isLoading={isLoading} />
          ),
          loadingContent: <ProjectSnapshots isLoading={true} />,
          actionButtons: projectButtons,
          // No role is required for the snapshots tab,
          // but it is hidden if the project is not allowed to use the feature
          isHidden: !isProjectAllowedToViewSnapshotsTab,
        },
        {
          label: "Annotations",
          route: ProjectTabs.annotations,
          content: (project) => <ProjectMarkups projectId={project.id} />,
          loadingContent: <div />,
          actionButtons: projectButtons,
          requiredRoleProjectLevel: shouldDisplayMarkupsForViewers
            ? RequiredRoleProjectLevelName.canViewProjectMarkupsAllowViewers
            : RequiredRoleProjectLevelName.canViewProjectMarkups,
        },
        {
          label: "Integrations",
          route: ProjectTabs.integrations,
          content: (project) => <ProjectIntegrations projectId={project.id} />,
          loadingContent: <div />,
          actionButtons: projectButtons,
          requiredRoleProjectLevel:
            RequiredRoleProjectLevelName.canViewProjectIntegrations,
        },
        {
          label: "Settings",
          route: ProjectTabs.settings,
          content: (project) => (
            <ProjectSettings projectId={project.id} isLoading={isLoading} />
          ),
          loadingContent: <ProjectSettings isLoading={true} />,
          actionButtons: projectButtons,
          requiredRoleProjectLevel:
            RequiredRoleProjectLevelName.canViewProjectSettings,
        },
      ]}
    />
  );
}
