import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { IElementTypeHint } from "@faro-lotv/ielement-types";
import { isScanEntity } from "@utils/capture-tree-utils";
import {
  fetchAllRegistrationRevisions,
  fetchCaptureTreeForMainRevision,
} from "@store/capture-tree/capture-tree-thunks";
import { setFetchCaptureTreeDataError } from "@store/capture-tree/capture-tree-slice";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { useAppDispatch } from "@store/store-helper";
import { useCallback, useEffect } from "react";
import { usePolling } from "@hooks/use-polling";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { fetchTasksByType } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { resetSdbBackgroundTasksState } from "@store/sdb-background-tasks/sdb-background-tasks-slice";

/**
 * Custom hook that polls data required for the Data Management page
 */
export function useDataManagement({ projectId }: BaseProjectIdProps): void {
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const progressApiClient = useProgressApiClient({
    projectId: projectId.toString(),
  });

  /**
   * Fetches:
   * - The capture tree entities for the main revision of the current project
   * - The registration revisions of the current project
   * - The ProgressAPI tasks of type "CloudRegistration"
   * - The published ELS point cloud ielements, which is used to generate the PublishedData entities
   * - The scan ielements and all their children ielements (PointCloudElsRaw, PointCloudE57, PointCloudStream, etc.)
   * The presence of the PointCloudStream as children of the scan indicates that the scan has been processed.
   *
   * @throws {error} if the requests to fetch capture tree data fail.
   */
  const fetchCaptureTreeData = useCallback(async (): Promise<void> => {
    const entitiesPromise = dispatch(
      fetchCaptureTreeForMainRevision({ projectApiClient })
    ).unwrap();
    const revisionsPromise = dispatch(
      fetchAllRegistrationRevisions({ projectApiClient })
    ).unwrap();
    const cloudRegistrationTasksPromise = dispatch(
      fetchTasksByType({
        progressApiClient,
        taskType: "CloudRegistration",
      })
    ).unwrap();

    const [entities] = await Promise.all([
      entitiesPromise,
      revisionsPromise,
      cloudRegistrationTasksPromise,
    ]);

    const scanEntities = entities.filter(isScanEntity);
    const scanEntitiesIds = scanEntities.map((entity) => entity.id);

    await dispatch(
      fetchProjectIElements({
        fetcher: async () => {
          const rootElementPromise = projectApiClient.getRootIElement();

          const scanElementsPromise = projectApiClient.getAllIElements({
            ids: scanEntitiesIds,
          });

          const scanChildrenElementsPromise = projectApiClient.getAllIElements({
            ancestorIds: scanEntitiesIds,
          });

          const publishedElsPointCloudPromise =
            projectApiClient.getAllIElements({
              typeHints: [IElementTypeHint.dataSetEls],
            });

          const [
            rootElement,
            scanElements,
            scanChildrenElements,
            publishedElsPointCloud,
          ] = await Promise.all([
            rootElementPromise,
            scanElementsPromise,
            scanChildrenElementsPromise,
            publishedElsPointCloudPromise,
          ]);

          return [
            rootElement,
            ...scanElements,
            ...scanChildrenElements,
            ...publishedElsPointCloud,
          ];
        },
      })
    ).unwrap();
  }, [dispatch, progressApiClient, projectApiClient]);

  /**
   * Error handler callback, if the 1st try fails.
   * We show an error page in this case, since we don't know the current state of the project.
   */
  const errorHandlerFirstLoad = useCallback((): void => {
    dispatch(setFetchCaptureTreeDataError(true));
  }, [dispatch]);

  usePolling({
    callback: fetchCaptureTreeData,
    errorHandlerFirstLoad,
    errorHandler: undefined,
    maxAttemptsMsg: "Failed to fetch the uploaded data for this project! Please reload the page and try again.",
  });

  /**
   * Resets the background tasks store on component unmount.
   * This is done to avoid any potential problem with the Project Activity tab, that tab shows all background tasks
   * and since we already fetched some tasks it might interfere with how pagination works in Activity tab.
   */
  useEffect(() => {
    return () => {
      dispatch(resetSdbBackgroundTasksState());
    };
  }, [dispatch]);
}
