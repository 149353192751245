import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { BaseTeamProps, SdbTeamMemberBase } from "@custom-types/teams-types";
import { MembersTable } from "@components/table/members/members-table";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  fetchingTeamsFlagsSelector,
  nextTeamMemberSelector,
  selectedTeamMembersSelector,
  isDetailOpenedSelector,
} from "@store/teams/teams-selector";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { useEffect, useMemo } from "react";
import { TeamDetailsInfoBar } from "@pages/members/teams/team-details/team-details-info-bar";
import { mapTeamMembersToSdbMembers } from "@components/table/teams/teams-table-utils";
import { TeamMembersPageBulkActions } from "@pages/members/teams/team-members-page-bulk-actions";
import { useCoreApiClient } from "@api/use-core-api-client";
import { LoadMoreButton } from "@components/common/button/load-more-button";
import { fetchTeamMembers } from "@store/teams/teams-slice-thunk";
import { MembersEmptyPage } from "@components/common/empty-page/members-empty-page";
import { InviteMemberToTeam } from "@pages/members/teams/invite-member-to-team";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { TeamSidePanelDescription } from "@pages/members/teams/team-side-panel-description";
import { Box, Stack } from "@mui/material";

interface Props extends Partial<BaseTeamProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/** Side panel width in pixel */
const SIDE_PANEL_WIDTH = "320px";

/**
 * Contains the Members tab of the team details
 */
export function TeamMembers({ team, isLoading = false }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const teamMembers = useAppSelector(selectedTeamMembersSelector);
  const { isFetchingTeamMembers } = useAppSelector(fetchingTeamsFlagsSelector);

  const isDetailOpened = useAppSelector(isDetailOpenedSelector);

  /** Stores the id of the next team member to be fetched */
  const nextTeamMember = useAppSelector(nextTeamMemberSelector);

  /**
   * Decides whether to show the empty page or not.
   * - True if there is no members in the team
   */
  const shouldShowEmptyPage =
    !isFetchingTeamMembers && teamMembers.length === 0;

  // Fetch team members
  useEffect(() => {
    if (companyId && team?.id) {
      dispatch(
        fetchTeamMembers({
          coreApiClient,
          companyId,
          teamId: team.id,
          next: null,
        })
      );
    }
  }, [companyId, coreApiClient, dispatch, team?.id]);

  // Constructing the members list for members table from sample members of a team
  const sbdTeamMembers: SdbTeamMemberBase[] = useMemo(() => {
    if (!teamMembers.length) {
      return [];
    }

    return mapTeamMembersToSdbMembers(teamMembers);
  }, [teamMembers]);

  if (!companyId || !team) {
    return <NotFoundPage />;
  }

  const buttonComponents = TeamMembersPageBulkActions({
    companyId,
    team,
    teamMembers: sbdTeamMembers,
  });

  if (team && shouldShowEmptyPage) {
    return (
      <Stack flexDirection="row" gap={isDetailOpened ? "20px" : 0}>
        <MembersEmptyPage
          // eslint-disable-next-line max-len
          subtitle={`All the members of your ${TEAM_DISPLAY_NAME} will be found here. You can add members to your ${TEAM_DISPLAY_NAME} by inviting them.`}
          button={<InviteMemberToTeam team={team} buttonVariant="filled" />}
        />

        <Box
          sx={{
            overflow: "hidden",
            opacity: isDetailOpened ? 1 : 0,
            visibility: isDetailOpened ? "visible" : "hidden",
            width: isDetailOpened ? `${SIDE_PANEL_WIDTH}px` : "0",
            transition: "0.3s ease-in-out",
            mt: "14px",
          }}
        >
          <TeamSidePanelDescription team={team} />
        </Box>
      </Stack>
    );
  }

  async function loadMoreMembers(): Promise<void> {
    if (companyId && team?.id && nextTeamMember) {
      dispatch(
        fetchTeamMembers({
          coreApiClient,
          companyId,
          teamId: team.id,
          next: nextTeamMember,
        })
      );
    }
  }

  return (
    <>
      <TeamDetailsInfoBar isLoading={isLoading} />

      <MembersTable
        companyId={companyId}
        members={sbdTeamMembers}
        team={team}
        tableSubject="team"
        isLoading={isFetchingTeamMembers || isLoading}
        requiredColumns={[
          MemberHeaders.avatar,
          MemberHeaders.user,
          MemberHeaders.email,
          MemberHeaders.status,
          MemberHeaders.options,
        ]}
        bulkActionButtons={buttonComponents}
        sidePanelContent={<TeamSidePanelDescription team={team} />}
        sidePanelContentWidth={SIDE_PANEL_WIDTH}
        isSidePanelOpened={isDetailOpened}
      />

      <LoadMoreButton
        isLoading={isFetchingTeamMembers}
        buttonText="Load more members"
        shouldHideLoadMoreButton={!nextTeamMember}
        onClick={loadMoreMembers}
      />
    </>
  );
}
