import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { App } from "@src/App";
import {
  AddOnsTabs,
  AnalyticsTabs,
  GroupTabs,
  MainRoutes,
  MemberTabs,
  ProjectTabs,
  SettingsTabs,
  TeamTabs,
  UsersTabs,
} from "@router/route-params";
import { CustomErrorBoundary } from "@router/custom-error-boundary";
import { MainPageLayout } from "@components/main-page-layout";
import { ProjectArchivingState } from "@custom-types/project-types";
import { ProjectDetails } from "@pages/project-details/project-details";
import { AuthenticatedRoute } from "@router/authenticated-route";
import {
  getAnalyticsBreadcrumbHandle,
  getGroupBreadcrumbHandle,
  getGroupsBreadcrumbHandle,
  getMemberProfileBreadcrumbHandle,
  getMembersBreadcrumbHandle,
  getProjectBreadcrumbHandle,
  getProjectsBreadcrumbHandle,
  getTeamBreadcrumbHandle,
  getTeamsBreadcrumbHandle,
  getWorkspaceAddonsBreadcrumbHandle,
  getWorkspaceSettingsBreadcrumbHandle,
} from "@utils/faro-breadcrumb-utils";
import { ForbiddenPage } from "@pages/forbidden-page";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { GroupsPage } from "@pages/groups/groups-page";
import { GroupDetails } from "@pages/group-details/group-details";
import { MemberPage } from "@pages/profile/member-page";
import { AnalyticsPage } from "@pages/analytics/analytics-page";
import { WorkspaceSettingsPage } from "@pages/settings/workspace-settings-page";
import { RedirectUnknownRoutes } from "@pages/redirect-unkown-routes";
import { AddOnsPage } from "@pages/add-ons/add-ons";
import { ValidateProjectStatus } from "@pages/projects/validate-project-status";
import { DataManagement } from "@pages/project-details/project-data-management/data-management";
import { MembersWorkspace } from "@pages/members/members-workspace";
import { TeamOverview } from "@pages/members/teams/team-overview";
import { PreservingNavigate } from "@components/common/preserve-navigation/preserving-navigate";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Root page that will prompt the user to select a company first. */}
      <Route
        path={MainRoutes.root}
        element={<App />}
        errorElement={<CustomErrorBoundary />}
      />
      {/* Main page with company id to instantiate the App component /:companyId */}
      <Route
        path={MainRoutes.app}
        element={<App />}
        errorElement={<CustomErrorBoundary />}
      >
        {/* Defines the layout of the page that it is visible in all pages,
            including the header, breadcrumbs and page content */}
        <Route element={<MainPageLayout />}>
          {/* Route page with company id to redirect to projects page /:companyId/ */}
          <Route
            path={`${MainRoutes.app}/`}
            element={
              <PreservingNavigate
                to={`${MainRoutes.projects}/${ProjectArchivingState.active}`}
              />
            }
          />
          {/* Projects page before project status to /:companyId/projects */}
          <Route
            path={MainRoutes.projects}
            handle={getProjectsBreadcrumbHandle()}
          >
            {/* Projects page to redirect to /:companyId/projects/active */}
            <Route
              index
              element={<PreservingNavigate to={ProjectArchivingState.active} />}
            />
            {/* Projects page with project status /:companyId/projects/:projectStatus */}
            <Route path={":projectStatus"}>
              <Route index element={<ValidateProjectStatus />} />
            </Route>
          </Route>

          <Route
            path={MainRoutes.projects}
            handle={getProjectsBreadcrumbHandle()}
          >
            {/* Project page /:companyId/project/:projectId */}
            <Route path={":projectId"} handle={getProjectBreadcrumbHandle()}>
              {/* Project details that redirects to overview tab */}
              {/* /:companyId/projects:projectStatus/:projectId */}
              <Route
                index
                element={<PreservingNavigate to={ProjectTabs.overview} />}
              />

              {/* <Redirect from="teams" to="members" /> */}
              <Route path={"team"} element={<RedirectUnknownRoutes />} />

              {/* Project details page using page tabs /:companyId/projects:projectStatus/:projectId */}
              <Route path={":projectTabs"} element={<ProjectDetails />} />
            </Route>
          </Route>

          {/* Members route: /members */}
          <Route
            path={MainRoutes.members}
            handle={getMembersBreadcrumbHandle()}
          >
            <Route
              index
              element={<PreservingNavigate to={UsersTabs.general} />}
            />

            {/* Settings page with settings type: /:companyId/users/:usersTab */}
            <Route path={":usersTab"}>
              <Route
                index
                element={
                  <AuthenticatedRoute
                    key={"members"}
                    requiredRoleCompanyLevel={
                      RequiredRoleCompanyLevelName.canViewAllCompanyUsers
                    }
                  >
                    <MembersWorkspace />
                  </AuthenticatedRoute>
                }
              />
            </Route>

            {/* Member profile page: /members/:memberId */}
            <Route
              path={":memberId"}
              handle={getMemberProfileBreadcrumbHandle()}
            >
              <Route
                index
                element={<PreservingNavigate to={MemberTabs.overview} />}
              />

              {/* Member page tab: /members/:memberId/:memberTabs */}
              <Route
                path={":memberTabs"}
                element={
                  <AuthenticatedRoute
                    key={"member-page"}
                    requiredRoleCompanyLevel={
                      RequiredRoleCompanyLevelName.canViewCompanyMemberProfile
                    }
                  >
                    <MemberPage />
                  </AuthenticatedRoute>
                }
              />
            </Route>
          </Route>

          {/* Groups route: /groups */}
          <Route path={MainRoutes.groups} handle={getGroupsBreadcrumbHandle()}>
            {/* Groups page: / */}
            <Route
              index
              element={
                <AuthenticatedRoute
                  key={"groups"}
                  requiredRoleCompanyLevel={
                    RequiredRoleCompanyLevelName.canViewAllCompanyGroups
                  }
                >
                  <GroupsPage />
                </AuthenticatedRoute>
              }
            />
            {/* Group details page: /:groupId */}
            <Route path={":groupId"} handle={getGroupBreadcrumbHandle()}>
              <Route
                index
                element={<PreservingNavigate to={GroupTabs.managers} />}
              />
              {/* Group details page tab: /:groupTabs */}
              <Route
                path={":groupTabs"}
                element={
                  <AuthenticatedRoute
                    key={"group-details"}
                    requiredRoleCompanyLevel={
                      RequiredRoleCompanyLevelName.canViewAllCompanyGroups
                    }
                  >
                    <GroupDetails />
                  </AuthenticatedRoute>
                }
              />
            </Route>
          </Route>

          {/* Analytics page before analytics type to /:companyId/analytics */}
          <Route
            path={MainRoutes.analytics}
            handle={getAnalyticsBreadcrumbHandle()}
          >
            {/* Analytics page to redirect to /:companyId/analytics/overview */}
            <Route
              index
              element={<PreservingNavigate to={AnalyticsTabs.workspace} />}
            />

            {/* Analytics page with analytics type /:companyId/analytics/:analyticsTabs */}
            <Route path={":analyticsTabs"}>
              <Route
                index
                element={
                  <AuthenticatedRoute
                    key={"analytics"}
                    requiredRoleCompanyLevel={
                      RequiredRoleCompanyLevelName.canViewAnalytics
                    }
                  >
                    <AnalyticsPage />
                  </AuthenticatedRoute>
                }
              />
            </Route>
          </Route>

          <Route
            path={MainRoutes.addons}
            handle={getWorkspaceAddonsBreadcrumbHandle()}
          >
            <Route
              index
              element={<PreservingNavigate to={AddOnsTabs.extensions} />}
            />

            {/* Addons page with addons type /:companyId/addons/:addOnsTab */}
            <Route path={":addOnsTab"}>
              <Route
                index
                element={
                  <AuthenticatedRoute key={"addons"}>
                    <AddOnsPage />
                  </AuthenticatedRoute>
                }
              />
            </Route>
          </Route>

          {/* Workspace settings page /:companyId/settings */}
          <Route
            path={MainRoutes.settings}
            handle={getWorkspaceSettingsBreadcrumbHandle()}
          >
            <Route
              index
              element={<PreservingNavigate to={SettingsTabs.general} />}
            />

            {/* Settings page with settings type: /:companyId/settings/:settingsTab */}
            <Route path={":settingsTab"}>
              <Route
                index
                element={
                  <AuthenticatedRoute
                    key={"workspace-settings"}
                    requiredRoleCompanyLevel={
                      RequiredRoleCompanyLevelName.canViewWorkspaceSettings
                    }
                  >
                    <WorkspaceSettingsPage />
                  </AuthenticatedRoute>
                }
              />
            </Route>
          </Route>

          {/* Teams route: /teams */}
          <Route path={MainRoutes.teams} handle={getTeamsBreadcrumbHandle()}>
            {/* Redirect to correct teams route if user tries to access directly */}
            <Route index element={<RedirectUnknownRoutes />} />

            {/* Team details page: /:teamId */}
            <Route path={":teamId"} handle={getTeamBreadcrumbHandle()}>
              <Route
                index
                element={<PreservingNavigate to={TeamTabs.members} />}
              />

              {/* Team details page tab: /:teamTabs */}
              <Route
                path={":teamTabs"}
                element={
                  <AuthenticatedRoute
                    key={"team-details"}
                    requiredRoleCompanyLevel={
                      RequiredRoleCompanyLevelName.canViewTeams
                    }
                  >
                    <TeamOverview />
                  </AuthenticatedRoute>
                }
              />
            </Route>
          </Route>

          {/* Forbidden page when the user does not have enough permissions */}
          <Route path={MainRoutes.forbidden} Component={ForbiddenPage} />

          {/* Default shows a not found page */}
          <Route path="*" Component={RedirectUnknownRoutes} />
        </Route>

        {/* Data management page using as main route /:companyId/projects/:projectId/data-management */}
        <Route
          index
          path={`${MainRoutes.app}/${MainRoutes.projects}/:projectId/${MainRoutes.dataManagement}`}
          element={<DataManagement />}
        />
      </Route>
    </Route>
  ),
  {
    // Enable future flags that introduces API breaking changes.
    // This allows to opt in to one change a time: https://reactrouter.com/en/main/guides/api-development-strategy
    future: {
      /* eslint-disable @typescript-eslint/naming-convention -- keys coming from external package */
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true,
      /* eslint-enable */
    },
  }
);
