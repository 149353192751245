import { Box, Breakpoint, SvgIcon } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { withEllipsisThreeLines } from "@styles/common-styles";
import { CSSProperties, ElementType } from "react";

interface Props {
  /** The icon to display on the first row of the page */
  icon: ElementType;

  /** The title to display on the second row of the page */
  title: string;

  /** The subtitle to display on the third row of the page */
  subtitle: string | JSX.Element;

  /** The button to display on the bottom of page */
  button?: React.ReactNode;
}

/** Defines the size of the icon in pixels */
const ICON_SIZE = "61px";

/** Defines the max width of the wrapper for the text (title and subtitle) */
const textMaxWidth: { [key in Breakpoint]: CSSProperties["maxWidth"] } = {
  xs: "90%",
  sm: "80%",
  md: "60%",
  lg: "50%",
  xl: "40%",
};

/** Since the colors or this page are custom, store them here to modify them easily */
const pageColors = {
  /** The background color of the whole empty page */
  pageBackground: sphereColors.gray50,

  /** The color of the title text */
  title: sphereColors.gray800,

  /** The color of the subtitle text */
  subtitle: sphereColors.gray600,
};

/**
 * Shows an empty page with an icon, title and subtitle.
 */
export function EmptyPage({
  title,
  subtitle,
  icon,
  button,
}: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        height: "744px",
        backgroundColor: pageColors.pageBackground,
        textAlign: "center",
        paddingTop: "110px",
        mt: "14px",
        flex: 1,
      }}
      data-testid="empty-page-container"
    >
      {/* Icon */}
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <Box
          component="div"
          sx={{
            width: "178px",
            height: "178px",
            backgroundColor: sphereColors.pureWhite,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgIcon
            inheritViewBox
            component={icon}
            sx={{
              height: ICON_SIZE,
              width: ICON_SIZE,
            }}
          />
        </Box>
      </Box>

      {/* Title */}
      <Box
        component="div"
        sx={{
          fontSize: "24px",
          fontWeight: "600",
          color: pageColors.title,
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Box
          component="div"
          sx={{
            maxWidth: textMaxWidth,
            ...withEllipsisThreeLines,
            lineHeight: "28px",
          }}
        >
          {title}
        </Box>
      </Box>

      {/* Subtitle */}
      <Box
        component="div"
        sx={{
          fontSize: "16px",
          color: pageColors.subtitle,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="div"
          sx={{
            maxWidth: textMaxWidth,
            ...withEllipsisThreeLines,
            lineHeight: "24px",
          }}
        >
          {subtitle}
        </Box>
      </Box>

      {/* Button */}
      {button && (
        <Box
          component="div"
          sx={{
            width: "100%",
            mt: "60px",
          }}
        >
          {button}
        </Box>
      )}
    </Box>
  );
}
