import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CaptureTreeState } from "@store/capture-tree/capture-tree-slice-types";
import { FetchingStatus } from "@store/store-types";
import {
  fetchAllRegistrationRevisions,
  fetchCaptureTreeForMainRevision,
} from "@store/capture-tree/capture-tree-thunks";
import { clearStore } from "@faro-lotv/project-source";
import {
  CaptureTreeEntity,
  RegistrationRevision,
} from "@faro-lotv/service-wires";

/** Pre-built reducers for the capture tree entities `normalized state` */
export const captureTreeForMainRevisionAdapter: EntityAdapter<CaptureTreeEntity, EntityId> =
  createEntityAdapter({
    selectId: (captureTreeEntity): EntityId => captureTreeEntity.id,
  });

/** Pre-built reducers for the registration revisions `normalized state` */
export const allRegistrationRevisionsAdapter: EntityAdapter<RegistrationRevision, EntityId> =
  createEntityAdapter({
    selectId: (registrationRevision): EntityId => registrationRevision.id,
  });

/** Initial state (readonly) of the capture tree store */
export const initialState: CaptureTreeState = {
  captureTreeForMainRevision:
    captureTreeForMainRevisionAdapter.getInitialState(),
  allRegistrationRevisions: allRegistrationRevisionsAdapter.getInitialState(),
  fetchingStatus: {
    captureTreeForMainRevision: FetchingStatus.uninitialized,
    allRegistrationRevisions: FetchingStatus.uninitialized,
  },
  hasFetched: {
    hasFetchedCaptureTreeForMainRevision: false,
    hasFetchedAllRegistrationRevisions: false,
  },
  fetchingError: false
};

/** Capture tree slice */
const captureTreeSlice = createSlice({
  name: "captureTree",
  initialState,
  reducers: {
    /** Sets the capture tree entities for main revision in the store */
    setCaptureTreeForMainRevision(
      state,
      action: PayloadAction<CaptureTreeEntity[]>
    ) {
      captureTreeForMainRevisionAdapter.setMany(
        state.captureTreeForMainRevision,
        action.payload
      );
    },

    /** Sets the registration revisions in the store */
    setAllRegistrationRevisions(
      state,
      action: PayloadAction<RegistrationRevision[]>
    ) {
      allRegistrationRevisionsAdapter.setMany(
        state.allRegistrationRevisions,
        action.payload
      );
    },
    setFetchCaptureTreeDataError: (state, action: PayloadAction<boolean>) => {
      state.fetchingError = action.payload;
    },
    clearFetchCaptureTreeDataError: (state) => {
      state.fetchingError = false;
    },

    /** Resets the capture tree store */
    resetCaptureTreeState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCaptureTreeForMainRevision.pending, (state) => {
        state.fetchingStatus.captureTreeForMainRevision =
          FetchingStatus.pending;
      })
      .addCase(fetchCaptureTreeForMainRevision.fulfilled, (state, action) => {
        captureTreeForMainRevisionAdapter.setMany(
          state.captureTreeForMainRevision,
          action.payload
        );
        state.fetchingStatus.captureTreeForMainRevision =
          FetchingStatus.succeeded;
        if (!state.hasFetched.hasFetchedCaptureTreeForMainRevision) {
          state.hasFetched.hasFetchedCaptureTreeForMainRevision = true;
        }
      })
      .addCase(fetchCaptureTreeForMainRevision.rejected, (state) => {
        state.fetchingStatus.captureTreeForMainRevision =
          FetchingStatus.rejected;
      })

      .addCase(fetchAllRegistrationRevisions.pending, (state) => {
        state.fetchingStatus.allRegistrationRevisions = FetchingStatus.pending;
      })
      .addCase(fetchAllRegistrationRevisions.fulfilled, (state, action) => {
        allRegistrationRevisionsAdapter.setMany(
          state.allRegistrationRevisions,
          action.payload
        );
        state.fetchingStatus.allRegistrationRevisions =
          FetchingStatus.succeeded;
        if (!state.hasFetched.hasFetchedAllRegistrationRevisions) {
          state.hasFetched.hasFetchedAllRegistrationRevisions = true;
        }
      })
      .addCase(fetchAllRegistrationRevisions.rejected, (state) => {
        state.fetchingStatus.allRegistrationRevisions = FetchingStatus.rejected;
      })

      /** Resets the capture tree store if it "listens" to the "clearStore" action */
      .addCase(clearStore, () => initialState);
  },
});

export const {
  setCaptureTreeForMainRevision,
  setAllRegistrationRevisions,
  resetCaptureTreeState,
  setFetchCaptureTreeDataError,
} = captureTreeSlice.actions;

export const captureTreeReducer = captureTreeSlice.reducer;
